import { l } from '@midaxo/kappa-shared';

// GraphQL `Int` type is a signed 32-bit integer
export const MAX_INTEGER_VALUE = 2147483647;

export const TEMPORARY_PROJECT_ID_PREFIX = '_';

// This value is set according to specification
export const SHORT_TEXT_FIELD_MAXIMUM_LENGTH = 400;

/** same as in css.  */
export const SMALL_SCREEN_WIDTH = 576;
export const MEDIUM_SCREEN_WIDTH = 768;
export const LARGE_SCREEN_WIDTH = 992;
// export const X_LARGE_SCREEN_WIDTH = 1200;

export const PROJECT_NAME_MAX_LENGTH = 255;

export const SSO_DOMAIN_STORAGE_KEY = 'SSO_DOMAIN_STORAGE_KEY';

export const ALL_DAY_DURATION_IN_MINUTES = 1440;

export const AG_GRID_LICENSE =
  'Using_this_{AG_Grid}_Enterprise_key_{AG-055936}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Midaxo_Oy}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Midaxo+}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Midaxo+}_need_to_be_licensed___{Midaxo+}_has_been_granted_a_Deployment_License_Add-on_for_{2}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_May_2025}____[v3]_[01]_MTc0NjMxMzIwMDAwMA==02b171e2ecbd5f796013c36d28e690b2';
export const ANALYTICS_COOKIES_CLEARED = 'analytics-cookies-cleared';

export const durationOptions = [
  { value: 0, label: l`0 minutes` },
  { value: 15, label: l`15 minutes` },
  { value: 30, label: l`30 minutes` },
  { value: 45, label: l`45 minutes` },
  { value: 60, label: l`1 hour` },
  { value: 90, label: l`1.5 hours` },
  { value: 120, label: l`2 hours` },
  { value: 150, label: l`2.5 hours` },
  { value: 180, label: l`3 hours` },
  { value: 240, label: l`4 hours` },
  { value: 300, label: l`5 hours` },
  { value: 360, label: l`6 hours` },
  { value: 420, label: l`7 hours` },
  { value: 480, label: l`8 hours` },
  { value: 540, label: l`9 hours` },
  { value: 600, label: l`10 hours` },
  { value: 660, label: l`11 hours` },
  { value: 720, label: l`12 hours` },
  { value: ALL_DAY_DURATION_IN_MINUTES, label: l`All day event` },
];
